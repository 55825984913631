// tslint:disable
import {
    AsyncResult,
    callActionOrExecute,
    DataServiceQuery,
    IContext,
    IDataServiceRequest,
    IQueryResultSettings
} from '@msdyn365-commerce/retail-proxy';
// @ts-ignore
import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import * as DataServiceEntities from './MZRSubmitRequestAssistanceEntities.g';
//import OrgUnitLocation from '@msdyn365-commerce/retail-proxy';

function MZRSubmitCustomerRequestAssistanceQuery(): DataServiceQuery {
    return new DataServiceQuery();
}

export function MZRSubmitCustomerRequestAssistanceInput(
    queryResultSettings: IQueryResultSettings,
    searchArea: Entities.SearchArea,
    requestInfo: DataServiceEntities.IMZRCustomerRequestInfo,
    deliveryModeTypeFilter: number
): IDataServiceRequest {
    const query = MZRSubmitCustomerRequestAssistanceQuery().resultSettings(queryResultSettings);
    return query.createDataServiceRequestForOperation(
        'MZRSubmitCustomerRequestAssistance',
        true,
        EntityClasses.OrgUnitLocationClass,
        'false',
        { bypassCache: 'get', returnEntity: 'Entities.OrgUnitLocation' },
        {
            searchArea: searchArea,
            requestInfo: requestInfo,
            deliveryModeTypeFilter: deliveryModeTypeFilter
        }
    );
}

export function MZRSubmitCustomerRequestAssistanceAsync(
    context: IContext,
    searchArea: Entities.SearchArea,
    requestInfo: DataServiceEntities.IMZRCustomerRequestInfo,
    deliveryModeTypeFilter: number
): AsyncResult<Entities.OrgUnitLocation[]> {
    const request = MZRSubmitCustomerRequestAssistanceInput(
        context.queryResultSettings || {},
        searchArea,
        requestInfo,
        deliveryModeTypeFilter
    );
    return callActionOrExecute<Entities.OrgUnitLocation[]>(request, context.callerContext);
}
