import * as React from 'react';
import { IComponentProps, AsyncResult } from '@msdyn365-commerce/core';
import { Customer } from '@msdyn365-commerce/retail-proxy';

import { MZRSubmitCustomerRequestAssistanceAsync } from '../../../actions/MZRSubmitRequestAssistanceActions.g';
import MzrCustomerRequestAssistanceModal from './mzr-customer-request-assistance-modal';

export interface IMzrCustomerRequestAssistanceProps extends IComponentProps<{}> {
    orgUnitLocationButtonText: string | undefined;
    accountInfo: AsyncResult<Customer> | undefined;
}

interface IMzrCustomerRequestAssistanceState {
    isModalOpen: boolean;
}

class MzrCustomerRequestAssistance extends React.Component<IMzrCustomerRequestAssistanceProps, IMzrCustomerRequestAssistanceState> {
    constructor(props: IMzrCustomerRequestAssistanceProps) {
        super(props);
        this.state = { isModalOpen: false };
        this._submitCustomerRequestAssistance = this._submitCustomerRequestAssistance.bind(this);
        this._toggleModal = this._toggleModal.bind(this);
    }

    public render(): JSX.Element {
        return this._renderForm(this.props);
    }

    private _renderForm = (props: IMzrCustomerRequestAssistanceProps) => {
        return (
            <div>
                <button className='mzr-gift-card__apply-btn btn' onClick={this._toggleModal}>
                    {' '}
                    Request for assistance
                </button>
                {/* <button className='mzr-gift-card__apply-btn btn' onClick={this._toggleModal}>
                    {' '}
                    { <img src={this._getDressingRoomImage()} className='img-thumbnail' alt='' /> }
                    Request for assistance
                </button>{' '} */}
                <MzrCustomerRequestAssistanceModal
                    showModal={this.state.isModalOpen}
                    onClose={this._toggleModal}
                    onSubmit={this._submitCustomerRequestAssistance}
                    context={props.context}
                    id={props.id}
                    typeName={props.typeName}
                    data={{}}
                >
                    {' '}
                    Please let us know how we can help you:
                </MzrCustomerRequestAssistanceModal>
            </div>
        );
    };

    private _toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    };

    private async _submitCustomerRequestAssistance(detailsText: string) {
        let latitude: number = 0;
        let longitude: number = 0;
        if (window.localStorage._msdyn365__preferredStore_ !== undefined) {
            var preferredStore = JSON.parse(window.localStorage._msdyn365__preferredStore_);
            latitude = preferredStore.Latitude;
            longitude = preferredStore.Longitude;
            this._submitRequest(latitude, longitude, detailsText);
        } else if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function(position) {
                latitude = position.coords.latitude;
                longitude = position.coords.longitude;
            });
            this._submitRequest(latitude, longitude, detailsText);
        } else {
            /* geolocation IS NOT available */
        }

        this._toggleModal();
    }

    public async _submitRequest(latitude: number, longitude: number, detailsText: string) {
        await MZRSubmitCustomerRequestAssistanceAsync(
            { callerContext: this.props.context.actionContext },
            {
                Latitude: latitude,
                Longitude: longitude,
                Radius: 10,
                DistanceUnitValue: 0
            },
            {
                CustAccount: this.props.context.request.user.customerAccountNumber, //'004146',
                CustName: this.props.context.request.user.name, //'Kayvan Parmani',
                RequestNote: detailsText
            },
            2
        )
            .then(result => {
                console.log('<Success calling <MZRSubmitCustomerRequestAssistanceAsync>: number of stores found');
            })
            .catch(error => {
                console.log(error);
                //this.setState({ giftCardBalance: 0.00, giftCardInputValue: '', canApply: false, error: this.props.failedToGetGiftCardErrorText });
            });
    }
}

export default MzrCustomerRequestAssistance;
