import * as React from 'react';
import { Cart } from '@msdyn365-commerce/retail-proxy';
import { Button } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import { createQuoteAsync } from '../../actions/MzrQuotationService.g';
import Cookie from 'js-cookie';
import { IComponentProps, msdyn365Commerce } from '@msdyn365-commerce/core';
import { getCartState } from '@msdyn365-commerce/global-state';

export interface ICreateQuoteComponentData {
    cart: Readonly<Cart> | undefined;
}

export interface IMzrCreateQuoteComponentProps extends IComponentProps<ICreateQuoteComponentData> {
    buttonStyle: string | undefined;
    //className: string | undefined;
}

interface IMzrCreateQuoteComponentState {
    isModalOpen: boolean;
}

class MzrCreateQuoteComponent extends React.Component<IMzrCreateQuoteComponentProps, IMzrCreateQuoteComponentState> {
    constructor(props: IMzrCreateQuoteComponentProps) {
        super(props);
        this.state = { isModalOpen: false };
        this._submitCustomerRequestAssistance = this._submitCustomerRequestAssistance.bind(this);
        this._toggleModal = this._toggleModal.bind(this);
    }

    public render(): JSX.Element {
        return this._renderForm(this.props);
    }

    private _renderForm = (props: IMzrCreateQuoteComponentProps) => {
        return (
            <Button
                className={classnames(props.buttonStyle, { 'is-busy': false })}
                onClick={this._submitCustomerRequestAssistance}
                title='Save quote'
                //{...attributes}
                disabled={false}
            >
                Save Quote
            </Button>
        );
    };

    private _toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    };

    private async _submitCustomerRequestAssistance() {
        let cartCookieName = msdyn365Commerce.CartCookie || 'cart';
        let cartId = Cookie.get(cartCookieName) || '';
        if (cartId) {
            let currentCartState = await getCartState(this.props.context.actionContext);
            let activeCart = currentCartState.cart;
            if (activeCart && Object.keys(activeCart).length > 0) {
                const result = await createQuoteAsync({ callerContext: this.props.context.actionContext }, activeCart.Id);
                console.log(result);
            }
        }
    }
}

export default MzrCreateQuoteComponent;
