import React from 'react';
import { IComponentProps } from '@msdyn365-commerce/core';

export interface IMzrCustomerRequestAssistanceModalProps extends IComponentProps<{}> {
    showModal: boolean;
    onClose(): void;
    onSubmit(detailsText: string): void;
}

interface IMzrCustomerRequestAssistanceModalState {
    showModal: boolean;
    detailsText: string;
}

class MzrCustomerRequestAssistanceModal extends React.Component<
    IMzrCustomerRequestAssistanceModalProps,
    IMzrCustomerRequestAssistanceModalState
> {
    constructor(props: IMzrCustomerRequestAssistanceModalProps, state: IMzrCustomerRequestAssistanceModalState) {
        super(props);

        this._handleSubmit = this._handleSubmit.bind(this);
        this._handleTextAreaChange = this._handleTextAreaChange.bind(this);
        this.state = {
            showModal: this.props.showModal,
            detailsText: ''
        };
    }

    public render() {
        // Render nothing if the "show" prop is false
        if (!this.props.showModal) {
            return null;
        }

        return (
            <div className='mzr_customer_request_assistance_modal_backdrop'>
                <div className='mzr_customer_request_assistance_modal_modal'>
                    {' '}
                    {this.props.children}
                    <textarea
                        className='mzr_customer_request_assistance_modal_inputText'
                        value={this.state.detailsText}
                        onChange={this._handleTextAreaChange}
                    />
                    <button className='mzr_customer_request_assistance_modal_button' onClick={this._handleSubmit}>
                        {' '}
                        Send
                    </button>
                    <button className='mzr_customer_request_assistance_modal_button' onClick={this.props.onClose}>
                        {' '}
                        Close
                    </button>
                </div>
            </div>
        );
    }

    private _handleTextAreaChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({ detailsText: event.target.value });
    }

    private _handleSubmit() {
        this.props.onSubmit(this.state.detailsText);
        this.setState({
            showModal: false,
            detailsText: ''
        });
    }
}

export default MzrCustomerRequestAssistanceModal;
